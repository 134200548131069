<template>
  <tr>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-center">
      <strong>
        {{ props.item.id }}
      </strong>
    </td>
    <td class="justify-center">
      <div style="text-align: center;">
        <v-icon> {{props.item.icone_status}} </v-icon>
        <br>
        <strong :style="{ color: props.item.cor_status }">
          {{ props.item.nome_status }}
        </strong>
      </div>
    </td>
    <td class="justify-center">
      <strong>
        {{ props.item.tipo_status }}
      </strong>
    </td>
    <td class="justify-center">
      <strong>
        {{ props.item.exigir_data }}
      </strong>
    </td>
    <td class="justify-center">
      <strong>
        {{ props.item.get_confirmation }}
      </strong>
    </td>
    <td class="justify-center">
      <strong>
        {{ props.item.ordem_status }}
      </strong>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'TicketStatus',
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
.business__date {
  border-width: 1px;
  border-style: solid;
  padding: 0px 3px;
  color: white;
  text-transform: uppercase;
  font-size: 10px;
}
.business__date--new {
  border-color: green;
  background: rgba(0, 128, 0, 0.589);
}
.business__date--expired {
  border-color: red;
  background: rgba(255, 0, 0, 0.507);
}
td {
  padding: 0 5px !important;
}
th {
  padding: 0 5px !important;
}
.business__is-template {
  background: orange;
  border-color: rgb(145, 94, 0);
}
</style>